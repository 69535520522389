import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { connect } from 'react-redux'
import { TrendingLine } from '../assets/icons/TrendingLine'
import { URLS } from '../config'
import { makeRequest } from '../utilities/endpoints'
import cn from './HealthScore.module.css'
import { Loader, LOADER_SIZES, LOADER_TYPES } from './Loader'
import { Modal, MODAL_SIZES } from './Modal'
import { ProductUsageElement } from './ProductUsageElement'
import { Table } from './Table'
import { Tabs } from './Tabs'

const COMPARE_VALUES = {
    'EQUALS': 'Equals',
    'INCLUDES': 'Includes',
    'STARTS_WITH': 'Starts With',
    'ENDS_WITH': 'Ends With',
    'BETWEEN': 'Between',
    'BIGGER_THAN': '>',
    'LESSER_THAN': '<',
    'CHECKED': 'Checked'
}

export const HealthScore = ({ colorScheme, data, accountName, accountId }) => {
    const [showGraph, setShowGraph] = useState(false)
    const [riskSettings, setRiskSettings] = useState({ red: 0, green: 0 })
    const [previousAccountName, setPreviousAccountName] = useState()
    const [loaded, setLoaded] = useState()
    const [selectedTab, setSelectedTab] = useState(0)
    const [customColumns, setCustomColumns] = useState()
    const [riskContribution, setRiskContribution] = useState()
    const [formulaParts, setFormulaParts] = useState()

    const borderColor = colorScheme === 'DARK' ? '#FFFFFF' : '#111311'

    useEffect(() => {
        makeRequest.get(`${URLS.main}/risk/custom-columns`)
            .then(res => setCustomColumns(res.data))
    }, [])

    useEffect(() => {
        if (previousAccountName !== accountName && showGraph) {
            setPreviousAccountName(accountName)
            setLoaded(false)
            makeRequest.get(`${URLS.main}/risk/formula-parts`)
                .then(res => {
                    setRiskSettings(res?.data?.formulaSettings)
                    setFormulaParts(res?.data?.formulaParts)
                    setLoaded(true)
                })
            if (!!accountId) {
                makeRequest.get(`${URLS.main}/risk/result-breakdown/${accountId}`)
                    .then(res => setRiskContribution(res.data))
            }
        }
    }, [showGraph, accountName, previousAccountName, accountId])

    const graphConfig = {
        labels: null,
        datasets: [
            {
                data: !!data?.historicalData?.length ? data.historicalData.map(el => el.score) : [],
                fill: false,
                borderColor,
                tension: 0.1,
                label: `Risk over the last ${moment().diff(moment(!!data?.historicalData && data?.historicalData[0]?.timestamp), 'weeks')} weeks`
            },
            {
                data: Array(52).fill(riskSettings?.red),
                fill: false,
                borderColor: '#e10000',
                label: `Red Treshhold`
            },
            {
                data: Array(52).fill(riskSettings?.green),
                fill: false,
                borderColor: '#3eb984',
                label: `Green Treshhold`
            }
        ],
        fill: false,
        tension: 0.1,
    }

    const graphOptions = {
        type: 'line',
        indexAxis: 'x',
        maintainAspectRatio: false,
        plugins: {
            tooltip: {
                enabled: true
            }
        },
        scales: {
            y: {
                min: Math.min(...graphConfig.datasets[0].data, riskSettings?.red, riskSettings?.green, 0),
                max: Math.max(...graphConfig.datasets[0].data, riskSettings?.red, riskSettings?.green, 10) + 1
            }
        },
    };

    const insufficientData = data.historicalData?.length <= 1

    return <div
        className={`${cn.healthScore} ${cn.pointer}`}
        data-tooltip-id='default'
        data-tooltip-content={insufficientData ? 'Insufficient Data' : undefined}
        onClick={() => {
            if (!insufficientData) {
                setShowGraph(true)
            }
        }}
    >
        <ProductUsageElement data={data.color} />
        <div className={cn.text}>{data.score}</div>
        {!data.historicalData?.length ? null : data.historicalData[data.historicalData.length - 2]?.score <= data.score ? (
            <TrendingLine fill={'#A0FFBB'} />
        ) : (
            <div className={cn.flipped}>
                <TrendingLine fill={'#CB3D2C'} />
            </div>
        )}
        {showGraph && (
            <Modal
                minHeight
                minWidth
                header={`Risk Trend for '${accountName}'`}
                onClose={(e) => {
                    setShowGraph(false)
                    try {
                        // stop propagation, to avoid the event bubbling up to the parent node and triggering the click to open the modal
                        e.stopPropagation()
                    } catch {
                        // do nothing, it means it was closed via the esc button
                    }
                }}
                size={MODAL_SIZES.BIG}
            >
                <div className={cn.lineWrapper}>
                    {loaded ? (
                        <>
                            {!!accountId && !!riskContribution && !!customColumns && (
                                <>
                                    <Tabs
                                        selected={selectedTab}
                                        options={[
                                            'Historical Trend',
                                            'Distribution/Explanation'
                                        ]}
                                        onChange={index => setSelectedTab(index)}
                                    />
                                    <br />
                                </>
                            )}
                            {selectedTab === 0 ? (
                                <Line
                                    data={{
                                        ...graphConfig,
                                        labels: [...new Set(data.historicalData.map(el => moment(el.timestamp).format('MM.DD.YYYY')))],
                                    }}
                                    options={graphOptions}
                                />
                            ) : (
                                <>
                                    {!!riskContribution?.groupValues?.length ? (
                                        <>
                                            <Table
                                                headers={[
                                                    'Health Group',
                                                    'Contributing by',
                                                    'Max',
                                                    'Min',
                                                    'Participating Columns'
                                                ]}
                                                data={riskContribution?.groupValues.map(el => {
                                                    const columns = customColumns.filter(column => el.columns.includes(column.id)).map(el => el.label).join(', ')

                                                    return [
                                                        el.name,
                                                        el.score,
                                                        el.valueOverValue,
                                                        el.valueUnderValue,
                                                        columns
                                                    ]
                                                })}
                                            />
                                            <br/>
                                        </>
                                    ) : null}
                                    <Table
                                        headers={[
                                            'Column/Indicator',
                                            'Value',
                                            'Contributing by',
                                            'Max',
                                            'Min',
                                            'Improve by Matching',
                                            'Improvement potential'
                                        ]}
                                        customStyles={{
                                            2: {
                                                width: 160
                                            },
                                            3: {
                                                width: 80
                                            },
                                            4: {
                                                width: 80
                                            }
                                        }}
                                        data={riskContribution?.data?.map(el => {
                                            const columnLabel = customColumns?.find(customColumn => customColumn.id === el.columnId)?.label
                                            if (!columnLabel) {
                                                return null
                                            }

                                            const formulaPartsFound = formulaParts.filter(formulaPart => formulaPart.columnId === el.columnId).sort((a, b) => b.score - a.score)

                                            let value = 'N/A'
                                            if (el.visualData !== null && el.actualData !== null) {
                                                value = `${el.visualData} / ${el.actualData}`
                                            } else if (el.visualData !== null || el.actualData !== null) {
                                                value = (el.visualData || el.actualData)
                                            }

                                            let score = riskContribution?.details[el.columnId]
                                            if (!isNaN(parseInt(riskContribution?.details[el.columnId])) && !!riskContribution.divideBy) {
                                                score = Math.round((score / riskContribution.divideBy) * 100) / 100
                                            }

                                            let improveBy = ''
                                            let improvementValue = 0
                                            if (formulaPartsFound[0]?.score > riskContribution?.details[el.columnId]) {
                                                let index = formulaPartsFound.findIndex(formulaPart => formulaPart.score === riskContribution?.details[el.columnId])
                                                if (index === -1) {
                                                    index = formulaPartsFound.length
                                                }

                                                if (!!index) {
                                                    if (formulaPartsFound[index - 1]?.comparisor === 'CHECKED') {
                                                        improveBy += `Is Checked`
                                                    } else {
                                                        improveBy += `${COMPARE_VALUES[formulaPartsFound[index - 1]?.comparisor]} "${formulaPartsFound[index - 1]?.compareValue}"`
                                                    }
                                                    improvementValue = formulaPartsFound[index - 1]?.score
                                                }
                                            }

                                            return [
                                                columnLabel,
                                                value,
                                                score,
                                                formulaPartsFound[0]?.score || 'N/A',
                                                formulaPartsFound[formulaPartsFound.length - 1]?.score || 'N/A',
                                                improveBy,
                                                improvementValue
                                            ].filter(el => el !== -654)
                                        }).filter(el => !!el)}
                                    />
                                </>
                            )}
                        </>
                    ) : (
                        <Loader type={LOADER_TYPES.SPINNER} size={LOADER_SIZES.BIG} />
                    )}
                </div>
            </Modal>
        )}
    </div>
}

export const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps
    }
}

export const HealthScoreConnected = connect(mapStateToProps)(HealthScore)