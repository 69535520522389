import React, { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { Bar, Doughnut } from 'react-chartjs-2'
import { Chart } from 'chart.js';
import cn from './Home.module.css'
import { ViewContainer } from '../../components/ViewContainer'
import { Row } from '../../components/Row'
import { Heading } from '../../components/Heading'
import { TasksConnected } from '../tasks/Tasks'
import { makeRequest } from '../../utilities/endpoints'
import { URLS } from '../../config'
import { Table } from '../../components/Table'
import { HealthScoreConnected } from '../../components/HealthScore'
import { AccountActions } from '../../components/AccountActions'
import { OpportunityActions } from '../../components/OpportunityActions'
import { Dropdown } from '../../components/Dropdown'
import { Button } from '../../components/Button'
import { Checkbox } from '../../components/Checkbox'
import { Radio } from '../../components/Radio'
import { useOutsideClickHandler } from '../../customHooks/useOutsideClickHandler'
import { NBSP_SIZES, Nbsp } from '../../components/Nbsp'
import { UsageGraph } from '../accounts/account/components/UsageGraph'
import { formatLargeSums } from '../../utilities/currency'
import { LOADER_SIZES, LOADER_TYPES, Loader } from '../../components/Loader'
import { permissionCheck } from '../../utilities/permissionCheck'
import { PermissionCheck } from '../../components/PermissionCheck'
import { workspaceTypeCheck } from '../../utilities/workspaceType'
import { translateCRMLabel } from '../../utilities/translateCRMLabels'
import { TrendingLine } from '../../assets/icons/TrendingLine'
import { ArrowDiagonalUp } from '../../assets/icons/ArrowDiagonalUp'
import { changeCustomRouteLabel, setHomeFilterData, } from '../../store/actions'
import { Modal } from '../../components/Modal'
import { Label } from '../../components/Label'
import { RiskWorkspaceTrend } from '../risk/components/RiskWorkspaceTrend'
import { environmentCheck } from '../../utilities/environmentCheck';
import { getUserCrmId } from '../../utilities/userCrmId'

const circleArrow = {
    id: 'circleArrow',
    beforeDatasetDraw(chart, args) {
        const { ctx } = chart
        ctx.save()
        ctx.shadowBlur = 4;
        // eslint-disable-next-line no-underscore-dangle
        ctx.shadowColor = args.meta?._dataset?.backgroundColor || "#A0FFBB";
    },
    afterDatasetDraw(chart) {
        const { ctx } = chart
        ctx.shadowBlur = 0;
    },
    afterDatasetsDraw(chart) {
        const { ctx } = chart

        const radius = 3
        ctx.save()
        ctx.shadowBlur = 9;

        chart.getDatasetMeta(0).data.forEach((dataPoint) => {
            if (dataPoint.height === 0) {
                return
            }

            const xPos = dataPoint.x
            const yPos = dataPoint.y

            ctx.beginPath()
            ctx.shadowColor = dataPoint.options.backgroundColor
            ctx.fillStyle = dataPoint.options.backgroundColor
            ctx.arc(xPos, yPos - radius / 2, radius, 0, Math.PI / 180 * 360, false)
            ctx.fill()
        })
        ctx.shadowBlur = 0;
    }
}

Chart.register(circleArrow);
const RemoveOrRearrangeDropdown = ({ positions, onChange, value='-' }) => (
    <Dropdown
        className={cn.removeOrRearrangeDropdown}
        values={[
            {
                label: '...',
                value: '...'
            },
            ...new Array(positions).fill(0).map((_el, i) => ({
                label: value !== i ? `Move to ${i + 1}` : `Position ${i + 1}`,
                value: i
            })),
            {
                label: 'Remove',
                value: 'remove'
            }
        ]}
        value={value}
        onChange={onChange}
    />
)

const AccountsHeading = ({ count, path }) => (
    <Link to={path}>
        <div className={cn.accountsHeading}>
            <div className={cn.count}>
                {count}
            </div>
            <div className={cn.accountsLabel}>
                accounts
            </div>
            <div>
                <ArrowDiagonalUp />
            </div>
        </div>
    </Link>
)

const GraphExplanationWrapper = ({ children }) => (
    <div className={cn.graphExplanationWrapper}>{children}</div>
)

const PotentialGain = ({ children }) => (
    <div className={cn.potentialGainWrapper}>
        <div className={cn.line}>
            <TrendingLine />
        </div>
        <div className={cn.text}>
            {children}
        </div>
    </div>
)

const PotentialLoss = ({ children }) => (
    <div className={cn.potentialLossWrapper}>
        <div className={cn.line}>
            <TrendingLine />
        </div>
        <div className={cn.text}>
            {children}
        </div>
    </div>
)

const ExplanationLabel = ({ label, type }) => {
    const convertTypeToColor = (type) => {
        switch (type) {
        case 'greenish':
            return cn.greenishIndicator
        case 'orange':
            return cn.orangeIndicator
        case 'blueish':
            return cn.blueishIndicator
        case 'green':
            return cn.greenIndicator
        case 'red':
            return cn.redIndicator
        case 'yellow':
            return cn.yellowIndicator
        }
    }
    return <div className={cn.explanationLabel}>
        <div className={convertTypeToColor(type)} />
        <div className={cn.label}>
            {label}
        </div>
    </div>
}

export const Home2 = ({ dispatch, filterByUserId, user, colorScheme }) => {
    let userId = user?.id
    if (workspaceTypeCheck(['SFDC', 'SFDC_SANDBOX'])) {
        userId = user?.sfUserId
    } else if (workspaceTypeCheck(['HUBSPOT'])) {
        userId = user?.hubspotUserId
    }
    if (filterByUserId === undefined) {
        filterByUserId = userId
    }

    const [totalAccounts, setTotalAccounts] = useState(false)
    const [accountsAtRisk, setAccountsAtRisk] = useState()
    const [accountsAtExpansion, setAccountsAtExpansion] = useState()
    const [showCardOptions, setShowCardOptions] = useState(false)
    const [showUsage, setShowUsage] = useState(false)
    const [renewalsAtRisk, setRenewalsAtRisk] = useState()
    const [accountsRiskBreakdown, setAccountsRiskBreakdown] = useState()
    const [bookOfBusiness, setBookOfBusiness] = useState()
    const [expansionBreakDown, setExpansionBreakDown] = useState()
    const [meetingsToday, setMeetingsToday] = useState()
    const [expansionHistoricalData, setExpansionHistoricalData] = useState({})
    const [metricsThisMonth, setMetricsThisMonth] = useState()
    const [metricsPerMonth, setMetricsPerMonth] = useState()
    const [riskLastMonth, setRiskLastMonth] = useState()
    const [topExpansionOpportunities, setTopExpansionOpportunities] = useState()
    const [topRenewalsOpportunities, setTopRenewalsOpportunities] = useState()
    const [settings, setSettings] = useState({})
    const [teams, setTeams] = useState([])
    const [loadedHomeSettings, setLoadedHomeSettings] = useState(false)

    const [accountsBecameGreen, setAccountsBecameGreen] = useState()
    const [accountsBecameYellow, setAccountsBecameYellow] = useState()
    const [accountsBecameRed, setAccountsBecameRed] = useState()

    const [customGraphs, setCustomGraphs] = useState([])
    const [customGraphData, setCustomGraphData] = useState({})

    const [customRiskReports, setCustomRiskReports] = useState([])
    const [customSalesforceReports, setCustomSalesforceReports] = useState([])
    const [customSalesforceReportsData, setCustomSalesforceReportsData] = useState({})
    const [fullyExpanded, setFullyExpanded] = useState([])


    const cardsOptionWrapperRef = useRef(null)

    const graphBorderColor = colorScheme === 'DARK' ? '#424944' : '#DCE0DD'

    const doughnutData = {
        datasets: [
            {
                data: [12, 7],
                backgroundColor: [
                    'rgba(255, 0, 0, 0.75)',
                    '#3EB984',
                ],
                borderColor: [
                    graphBorderColor,
                    graphBorderColor,
                    graphBorderColor,
                ],
                borderWidth: 15,
                cutout: '60%'
            },
        ],
    };

    const accountsBreakdownData = {
        datasets: [
            {
                data: [3, 1, 2],
                backgroundColor: [
                    '#EB862A',
                    '#ABBDD3',
                    '#298072',
                ],
                borderColor: [
                    graphBorderColor,
                    graphBorderColor,
                    graphBorderColor,
                ],
                borderWidth: 15,
                cutout: '60%'
            },
        ],
    }

    const options = {
        indexAxis: 'x',
        maintainAspectRatio: false,
        animation: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true
            },
            circleArrow: false
        },
        scales: {
            xAxes: {
                stacked: true,
            },
        },
    };

    const churnData = {
        labels: new Array(12).fill(0).map((_el, i) => moment().subtract(i, 'months').format('MMMM')).reverse(),
        datasets: [
            {
                borderRadius: 0,
                barThickness: 1,
                label: 'Actual Churn',
                data: [1000, 1500, 3500],
                backgroundColor: '#A0FFBB',
            },
        ],
    }

    const expansionData = {
        labels: ['Last Quarter', 'Last Month', 'This Month'],
        datasets: [
            {
                borderRadius: 0,
                label: 'Expansion',
                barThickness: 58,
                data: [420000, 200000, 600000],
                backgroundColor: '#A0FFBB',
            },
        ],
    };

    const doughnutOptions = {
        animation: false,
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: false
            },
            circleArrow: false
        },
    }

    const getExpansionGraphData = userId => {
        setExpansionHistoricalData({})
        if (permissionCheck('GLOBAL_WHITE_SPACE_PLANNING', 'READ')) {
            makeRequest.get(`${URLS.main}/global-white-space-planning-home/${userId}`)
                .then(res => {
                    setExpansionHistoricalData(res.data)
                })
        }
    }

    const getCustomGraphs = () => {
        makeRequest.get(`${URLS.main}/custom-graph-labels`)
            .then(res => setCustomGraphs(res.data))
    }

    const getCustomRiskReports = () => {
        makeRequest.get(`${URLS.main}/risk/workspace-trends`)
            .then(res => setCustomRiskReports(res.data.data.map(el => ({ ...el, type: 'CUSTOM_RISK_REPORT' }))))
    }

    const toggleFullyExpanded = id => {
        if (fullyExpanded.includes(id)) {
            setFullyExpanded(fullyExpanded.filter(el => el !== id))
        } else {
            setFullyExpanded([...fullyExpanded, id])
        }
    }

    const getCardLabel = card => allPotentialCards.find(el => el.value === card)?.label

    const allPotentialCards = [
        {
            label: `${translateCRMLabel('Accounts')} Breakdown`,
            value: 'ACCOUNTS_GRAPH'
        },
        {
            label: 'Expansions Graph',
            value: 'EXPANSION_GRAPH'
        },
        {
            label: `${translateCRMLabel('Accounts')} Health Breakdown`,
            value: 'ACCOUNTS_HEALTH_BREAKDOWN'
        },
        {
            label: `${translateCRMLabel('Accounts')} Health Breakdown vs Last Month`,
            value: 'ACCOUNTS_HEALTH_BREAKDOWN_VS_LAST_MONTH'
        },
        {
            value: 'BOOK_OF_BUSINESS',
            label: 'Your Book of Business'
        },
        {
            value: 'EXPANSION_OVER_TIME',
            label: 'Expansion Potential'
        },
        // {
        //     value: '`CHURN`',
        //     label: 'Churn'
        // },
        // todo, hide this just for prod
        {
            value: 'CONTRACTION',
            label: 'Contraction'
        },
        {
            label: 'Your Plays for today',
            value: 'PLAYS'
        },
        {
            label: `5 ${translateCRMLabel('Accounts')} at Risk`,
            value: 'ACCOUNTS_AT_RISK',
        },
        {
            label: `10 ${translateCRMLabel('Accounts')} at Risk`,
            value: '10_ACCOUNTS_AT_RISK',
        },
        // todo, hide this just for prod
        {
            // DONE!
            value: 'RENEWALS_AT_RISK',
            label: 'Renewals at Risk'
        },
        {
            label: 'Top Expansion Opportunities',
            value: 'TOP_EXPANSION_OPPORTUNITIES'
        },
        {
            label: 'Top Renewal 5 Opportunities',
            value: 'TOP_RENEWAL_OPPORTUNITIES'
        },
        {
            label: 'Top Renewal 10 Opportunities',
            value: 'TOP_RENEWAL_10_OPPORTUNITIES'
        },
        {
            value: 'EXPANSIONS',
            label: 'Expansion Potential'
        },
        {
            // DONE!
            value: 'MEETINGS_TODAY',
            label: 'Meetings Today'
        }
    ].filter(el => {
        if (environmentCheck(['PROD'])) {
            return !['CONTRACTION', 'CHURN', 'ACCOUNTS_GRAPH'].includes(el.value)
        }
        return true
    })
    const [cards, setCards] = useState(allPotentialCards.map(el => el.value))
    const [lastCards, setLastCards] = useState(allPotentialCards.map(el => el.value))

    useOutsideClickHandler(cardsOptionWrapperRef, () => {
        setShowCardOptions(false)
    })

    useEffect(() => {
        if (!!customGraphs.length && !!cards.length && !!loadedHomeSettings) {
            setCustomGraphData({})
            Promise.all(customGraphs.filter(el => Object.values(cards).includes(el.id)).map(el => (
                makeRequest.get(`${URLS.main}/custom-graph/${el.id}/${filterByUserId}`)
            )))
                .then(data => {
                    const obj = {}
                    data.forEach(el => obj[el.data.customGraph.id] = el.data)
                    setCustomGraphData(obj)
                })
        }
    }, [customGraphs, cards, loadedHomeSettings, filterByUserId])

    const toggleShowCardOptions = () => {
        setShowCardOptions(!showCardOptions)
    }

    const getSettings = () => {
        makeRequest.get(`${URLS.main}/settings`, {}, true)
            .then(res => {
                if (res && res.data) {
                    const obj = {}
                    res.data.forEach(setting => {
                        obj[setting.name] = setting.value
                        if (setting.name === 'useBoth') {
                            obj[setting.name] = setting.value === 'true'
                        }
                    })
                    setSettings(obj)
                    const customReports = res.data.find(el => el.name === 'selectedHomePageReports')
                    if (!!customReports?.value?.length) {
                        try {
                            const reports = JSON.parse(customReports?.value)
                            setCustomSalesforceReports(reports.map(el => ({ ...el, type: 'CUSTOM_RISK_REPORT' })))
                            const reportsData = {}
                            reports.forEach(el => {
                                getCustomSalesforceReport(el.id)
                                    .then(res => {
                                        reportsData[el.id] = res.data.tableData
                                        setCustomSalesforceReportsData(reportsData)
                                    })
                            })
                        // eslint-disable-next-line no-empty
                        } catch (e) {}
                    }
                }
            }).catch(error => {
                console.error(error);
            });
    }

    const getCustomSalesforceReport = id => {
        return makeRequest.get(`${URLS.main}/reports/details/${id}`)
    }

    const getTeams = () => {
        makeRequest.get(`${URLS.main}/teams`)
            .then(res => {
                if (res?.data?.teams) {
                    setTeams(res.data.teams)
                }
            })
    }

    useEffect(() => {
        getTeams()
        getCustomGraphs()
        getCustomRiskReports()
        dispatch(changeCustomRouteLabel(user?.name ? user?.name?.split(' ')[0] : user?.email))
    }, [])

    useEffect(() => {
        if (loadedHomeSettings) {
            if (cards.includes('ACCOUNTS_AT_RISK') || cards.includes('10_ACCOUNTS_AT_RISK')) {
                getTopRiskPlans(filterByUserId)
            }
            if (cards.includes('EXPANSIONS')) {
                getTopExpansionPlans(filterByUserId)
            }
            if (cards.includes('EXPANSION_GRAPH')) {
                getExpansionBreakDown(filterByUserId)
            }
            getRenewalsAtRisk(filterByUserId)
            getAccountsRiskBreakdown(filterByUserId)
            getBookOfBusiness(filterByUserId)
            if (cards.includes('CONTRACTION') || cards.includes('CHURN') || cards.includes('ACCOUNTS_GRAPH')) {
                getMetrics(filterByUserId)
            }
            if (cards.includes('EXPANSION_OVER_TIME')) {
                getExpansionGraphData(filterByUserId)
            }
            if (cards.includes('ACCOUNTS_HEALTH_BREAKDOWN_VS_LAST_MONTH')) {
                getRiskLastMonth(filterByUserId)
            }
            getTopExpansionOpportunities(filterByUserId)
            getTopRenewalsOpportunities(filterByUserId)
        } else {
            getHomeSettings()
            getSettings()
            getEventsToday(filterByUserId)
        }
    }, [filterByUserId, loadedHomeSettings])

    const getRiskLastMonth = userId => {
        if (permissionCheck('RISK_MANAGEMENT', 'READ')) {
            setAccountsBecameGreen({})
            setAccountsBecameYellow({})
            setAccountsBecameRed({})
            setRiskLastMonth(undefined)
            makeRequest.get(`${URLS.main}/risk/accounts-breakdown-period/month/${userId}`)
                .then(res => {
                    let accountsBecameGreen = 0
                    let accountsBecameYellow = 0
                    let accountsBecameRed = 0
                    let accountsBecameGreenArr = 0
                    let accountsBecameYellowArr = 0
                    let accountsBecameRedArr = 0
                    setTotalAccounts(res.data.thisMonthData.length)
                    res.data.thisMonthData.forEach(el => {
                        const previousData = res.data.lastMonthData.find(innerEl => el.accountId === innerEl.accountId)
                        if (el.color !== previousData?.color) {
                            switch (el.color) {
                            case 'GREEN':
                                accountsBecameGreen++
                                accountsBecameGreenArr += el.arr
                                break;
                            case 'YELLOW':
                                accountsBecameYellow++
                                accountsBecameYellowArr += el.arr
                                break;
                            case 'RED':
                                accountsBecameRed++
                                accountsBecameRedArr += el.arr
                                break;
                            }
                        }
                    })

                    setAccountsBecameGreen({
                        count: accountsBecameGreen,
                        arr: accountsBecameGreenArr
                    })
                    setAccountsBecameYellow({
                        count: accountsBecameYellow,
                        arr: accountsBecameYellowArr
                    })
                    setAccountsBecameRed({
                        count: accountsBecameRed,
                        arr: accountsBecameRedArr
                    })
                    setRiskLastMonth(res.data)
                })
        }
    }

    const getHomeSettings = () => {
        makeRequest.get(`${URLS.main}/home-settings`)
            .then(res => {
                if (res.data.positions && res.data.positions.length) {
                    setLastCards(res.data.positions)
                    setCards(res.data.positions)
                }
                setLoadedHomeSettings(true)
            })
    }

    const getMetrics = userId => {
        const metricsPerMonthData = []
        if (permissionCheck('METRICS', 'READ')) {
            setMetricsThisMonth(undefined)
            setMetricsPerMonth(undefined)
            // TODO - optimise this to be 1 request!
            makeRequest.get(`${URLS.main}/metrics/${moment().startOf('month').valueOf()}/${moment().endOf('month').valueOf()}/${userId}/false`)
                .then(res => {
                    setMetricsThisMonth(res.data)
                    metricsPerMonthData[0] = res.data
                })
            for (let i = 1; i <= 11; i++) {
                const temp = index => {
                    makeRequest.get(`${URLS.main}/metrics/${moment().subtract(index, 'month').startOf('month').valueOf()}/${moment().subtract(index, 'month').endOf('month').valueOf()}/${userId}/false`)
                        .then(res => {
                            metricsPerMonthData[index] = res.data
                            if (metricsPerMonthData.filter(el => !!el).length === 12) {
                                setMetricsPerMonth(metricsPerMonthData)
                            }
                        })
                }
                temp(i)
            }
        }
    }

    const getEventsToday = userId => {
        setMeetingsToday(undefined)
        makeRequest.get(`${URLS.main}/events-today/${userId}`)
            .then(res => {
                setMeetingsToday(res.data)
            })
    }

    const getExpansionBreakDown = userId => {
        if (permissionCheck('GLOBAL_WHITE_SPACE_PLANNING', 'READ')) {
            setExpansionBreakDown(undefined)
            Promise.all([
                makeRequest.get(`${URLS.main}/global-white-space-planning/breakdown/${userId}`),
                makeRequest.get(`${URLS.main}/products`),
            ]).then(([{ data: expansionBreakDown }, { data: productsRes }]) => {
                const updatedData = {
                    productsUpSellPotential: {},
                    productsCrossSellPotential: {}
                }
                productsRes.forEach(product => {
                    if (expansionBreakDown.productsUpSellPotential && expansionBreakDown.productsUpSellPotential[product.Id]) {
                        updatedData.productsUpSellPotential[product.Name] = expansionBreakDown.productsUpSellPotential[product.Id]
                    }
                    if (expansionBreakDown.productsCrossSellPotential && expansionBreakDown.productsCrossSellPotential[product.Id]) {
                        updatedData.productsCrossSellPotential[product.Name] = expansionBreakDown.productsCrossSellPotential[product.Id]
                    }
                })

                setExpansionBreakDown(updatedData)
            })
        }
    }


    const getBookOfBusiness = userId => {
        setBookOfBusiness(undefined)
        makeRequest.get(`${URLS.main}/accounts/book-of-business/${userId}`)
            .then(res => setBookOfBusiness(res.data?.bookOfBusiness))
    }

    const getAccountsRiskBreakdown = userId => {
        if (permissionCheck('RISK_MANAGEMENT', 'READ')) {
            setAccountsRiskBreakdown(undefined)
            makeRequest.get(`${URLS.main}/risk/accounts-breakdown/${userId}`)
                .then(res => setAccountsRiskBreakdown(res.data))
        }
    }


    const getRenewalsAtRisk = () => {
        if (permissionCheck('RISK_MANAGEMENT', 'READ')) {
            setRenewalsAtRisk(undefined)
            makeRequest.get(`${URLS.main}/risk/opportunities/renewals/${filterByUserId}`)
                .then(res => {
                    setRenewalsAtRisk(res.data?.opportunities || [])
                })
        }
    }

    const updateOnChange = (value, card) => {
        if (value === '-') {
            return
        }

        if (value === 'remove') {
            setCards(cards.filter(el => el !== card))
        } else {
            const copy = cards.filter(el => el !== card)
            copy.splice(value, 0, card)
            setCards(copy)
        }
    }

    useEffect(() => {
        if (lastCards.join('') !== cards.join('') && !!loadedHomeSettings) {
            setLastCards(cards)
            makeRequest.post(`${URLS.main}/home-settings`, {
                positions: cards
            })
        }
    }, [cards, loadedHomeSettings])

    const getTopRiskPlans = (filterByUserId) => {
        if (permissionCheck('RISK_MANAGEMENT', 'READ')) {
            setAccountsAtRisk(undefined)
            makeRequest.get(`${URLS.main}/risk/top/10`, {
                headers: {
                    filter: JSON.stringify({
                        filterOnAccountOwners: !!filterByUserId ? filterByUserId.includes(',') ? filterByUserId : [filterByUserId] : null,
                        orderBy: 'HealthScoreReverse',
                    })
                }
            })
                .then(res => {
                    setAccountsAtRisk(res.data?.tableData ? res.data.tableData.slice(1) : [])
                })
        }
    }

    const getTopExpansionPlans = (filterByUserId) => {
        if (permissionCheck('GLOBAL_WHITE_SPACE_PLANNING', 'READ')) {
            setAccountsAtExpansion(undefined)
            makeRequest.get(`${URLS.main}/global-white-space-planning/top/5`, {
                headers: {
                    filter: JSON.stringify({
                        filterOnAccountOwners: !!filterByUserId ? filterByUserId.includes(',') ? filterByUserId : [filterByUserId] : null,
                        orderByUpSellPotential: false,
                        orderByCrossSellPotential: false
                    })
                }
            })
                .then(res => {
                    const updatedData = (res.data?.tableData ? res.data.tableData.slice(1) : []).map(row => {
                        const current = row.filter(el => el.type === 'Opportunities_current')
                            .map(el => el?.data)
                            ?.flat()
                            ?.map(el => el.price)
                            ?.reduce((a, b) => a + b, 0)
                        const upSellPotential = row.filter(el => el.type === 'Opportunities_up_sell')
                            .map(el => el?.data)
                            ?.flat()
                            ?.map(el => el.potentialUpsell || 0)
                            ?.reduce((a, b) => a + b, 0)
                        const crossSellPotential = row.filter(el => el.type === 'Opportunities_cross_sell')
                            .map(el => el?.data)
                            ?.flat()
                            ?.map(el => el.potentialCrossSell || 0)
                            ?.reduce((a, b) => a + b, 0)

                        return [
                            row.find(el => el.type === 'Account'),
                            current,
                            upSellPotential,
                            crossSellPotential
                        ]
                    })
                    setAccountsAtExpansion(updatedData)
                })
        }
    }

    const getTopExpansionOpportunities = (filterByUserId) => {
        setTopExpansionOpportunities(undefined)
        makeRequest.get(`${URLS.main}/opportunities/top/expansion/5/${filterByUserId}`, {
        })
            .then(res => {
                setTopExpansionOpportunities(res.data?.opportunities)
            })
    }

    const getTopRenewalsOpportunities = (filterByUserId) => {
        setTopRenewalsOpportunities(undefined)
        makeRequest.get(`${URLS.main}/opportunities/top/renewal/10/${filterByUserId}`, {
        })
            .then(res => {
                setTopRenewalsOpportunities(res.data?.opportunities)
            })
    }

    let filterByUserIdForPlays = filterByUserId;
    if (!!filterByUserId) {
        if (!!teams?.length && filterByUserId.includes(',')) {
            const users = teams.map(el => el.users).flat()
            filterByUserIdForPlays = filterByUserId.split(',').map(el => users.find(user => getUserCrmId(user) === el)?.id).join(',')
        }
    }

    const expansionBreakDownCrossSellSum = expansionBreakDown?.productsCrossSellPotential ? Object.values(expansionBreakDown?.productsCrossSellPotential).reduce((a, b) => a + b, 0) : 0
    const expansionBreakDownUpSellSum = expansionBreakDown?.productsUpSellPotential ? Object.values(expansionBreakDown?.productsUpSellPotential).reduce((a, b) => a + b, 0) : 0

    return (
        <div className={cn.home}>
            {document.getElementById('top-nav-bar-portal') && createPortal(
                <>
                    <Nbsp size={NBSP_SIZES.BIG} />
                    <Button onClick={toggleShowCardOptions}>
                        Personalize
                    </Button>
                </>,
                document.getElementById('top-nav-bar-portal')
            )}
            {showCardOptions && (
                <Modal
                    header='Personalize Dashboard'
                    // buttons={}
                    onClose={() => setShowCardOptions(false)}
                >
                    <Radio
                        label={`See my data`}
                        checked={filterByUserId === userId || filterByUserId === undefined}
                        onClick={() => {
                            dispatch(setHomeFilterData(userId))
                        }}
                    />
                    <Radio
                        label={`See org's data`}
                        checked={filterByUserId === null}
                        onClick={() => {
                            dispatch(setHomeFilterData(null))
                        }}
                    />
                    {teams.map(team => {
                        const value = team.users.map(el => getUserCrmId(el)).filter(el => !!el).join(',')
                        if (!value?.length) {
                            return null
                        }
                        return (
                            <Radio
                                label={team.name}
                                checked={value === filterByUserId}
                                onClick={() => {
                                    dispatch(setHomeFilterData(value))
                                }}
                            />
                        )
                    }).filter(el => !!el)}
                    {!!customGraphs.length ? (
                        <Label>
                            Custom Graphs
                        </Label>
                    ) : null}
                    {customGraphs.map(el => (
                        <Row noMargin>
                            <Checkbox
                                label={el.label}
                                checked={cards.includes(el.id)}
                                onClick={() => {
                                    if (cards.includes(el.id)) {
                                        if (!!cards.filter(card => card !== el.id).length) {
                                            setCards(cards.filter(card => card !== el.id))
                                        }
                                    } else {
                                        setCards([...cards, el.id])
                                    }
                                }}
                            />
                        </Row>
                    ))}
                    {!!customSalesforceReports.length ? (
                        <Label>
                            Custom Salesforce Reports
                        </Label>
                    ) : null}
                    {customSalesforceReports.map(el => (
                        <Row noMargin>
                            <Checkbox
                                label={el.name}
                                checked={cards.includes(`CUSTOM_SALESFORCE_REPORT-${el.id}`)}
                                onClick={() => {
                                    if (cards.includes(`CUSTOM_SALESFORCE_REPORT-${el.id}`)) {
                                        if (!!cards.filter(card => card !== `CUSTOM_SALESFORCE_REPORT-${el.id}`).length) {
                                            setCards(cards.filter(card => card !== `CUSTOM_SALESFORCE_REPORT-${el.id}`))
                                        }
                                    } else {
                                        setCards([...cards, `CUSTOM_SALESFORCE_REPORT-${el.id}`])
                                    }
                                }}
                            />
                        </Row>
                    ))}
                    {!!customRiskReports.length ? (
                        <Label>
                            Custom Risk Reports
                        </Label>
                    ) : null}
                    {customRiskReports.map(el => (
                        <Row noMargin>
                            <Checkbox
                                label={el.label}
                                checked={cards.includes(`CUSTOM_RISK_REPORT-${el.id}`)}
                                onClick={() => {
                                    if (cards.includes(`CUSTOM_RISK_REPORT-${el.id}`)) {
                                        if (!!cards.filter(card => card !== `CUSTOM_RISK_REPORT-${el.id}`).length) {
                                            setCards(cards.filter(card => card !== `CUSTOM_RISK_REPORT-${el.id}`))
                                        }
                                    } else {
                                        setCards([...cards, `CUSTOM_RISK_REPORT-${el.id}`])
                                    }
                                }}
                            />
                        </Row>
                    ))}
                    {!!customGraphs.length || !!customRiskReports.length || !!customSalesforceReports.length ? (
                        <Label>
                            Standard Reports
                        </Label>
                    ) : null }
                    {allPotentialCards.map(el => (
                        <Row noMargin>
                            <Checkbox
                                label={el.label}
                                checked={cards.includes(el.value)}
                                onClick={() => {
                                    if (cards.includes(el.value)) {
                                        if (!!cards.filter(card => card !== el.value).length) {
                                            setCards(cards.filter(card => card !== el.value))
                                        }
                                    } else {
                                        setCards([...cards, el.value])
                                    }
                                }}
                            />
                        </Row>
                    ))}
                </Modal>
            )}
            <div className={cn.homeTestWrapper}>
                {
                    cards.filter(card => {
                        if (!!card?.indexOf && card.indexOf('CUSTOM_RISK_REPORT-') !== 0 && card.indexOf('CUSTOM_SALESFORCE_REPORT-') !== 0) {
                            return true
                        }
                        if (!!card?.indexOf && card.indexOf('CUSTOM_RISK_REPORT-') === 0 && !!customRiskReports.find(el => `CUSTOM_RISK_REPORT-${el.id}` === card)) {
                            return true
                        }
                        if (!!card?.indexOf && card.indexOf('CUSTOM_SALESFORCE_REPORT-') === 0 && !!customSalesforceReports.find(el => `CUSTOM_SALESFORCE_REPORT-${el.id}` === card)) {
                            return true
                        }
                        return false
                    }).map((card, cardIndex) => {
                        if (card.indexOf('CUSTOM_SALESFORCE_REPORT') === 0) {
                            const id = card.replace('CUSTOM_SALESFORCE_REPORT-', '')
                            return (
                                <ViewContainer className={`${cn.cardWrapper} ${cn.salesforceReport} ${fullyExpanded.includes(id) ? cn.unlimitedHeight : ''}`} testId={card} key={card}>
                                    <Row
                                        noMargin
                                        spaceBetween
                                        fullWidth
                                        dataTip={fullyExpanded.includes(id) ? 'Click to Contract' : 'Click to Fully Expand'}
                                        onClick={() => {
                                            toggleFullyExpanded(id)
                                        }}
                                    >
                                        <Heading>{`Salesforce Report ${customSalesforceReports.find(el => el.id === id)?.name}`}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {!!customSalesforceReportsData[id] ? (
                                        <Table
                                            className={cn.homeTable}
                                            borderless
                                            sticky={false}
                                            headers={customSalesforceReportsData[id][0].map(el => el.split('.'))}
                                            data={customSalesforceReportsData[id].slice(1, customSalesforceReportsData[id].length -2)}
                                        />
                                    ) : (
                                        <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} />
                                    )}
                                </ViewContainer>
                            )
                        }
                        if (card.indexOf('CUSTOM_RISK_REPORT-') === 0) {
                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{`Custom Risk Report ${customRiskReports.find(el => el.id * 1 === card.replace('CUSTOM_RISK_REPORT-', '') * 1)?.label}`}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    <div className={cn.riskWrapper}>
                                        <RiskWorkspaceTrend
                                            trendData={null}
                                            fixedHeight={302}
                                            trendDataConfig={{
                                                ...customRiskReports.find(el => `CUSTOM_RISK_REPORT-${el.id}` === card),
                                                trendUserId: filterByUserId || 'COMPANY_WIDE',
                                            }}
                                        />
                                    </div>
                                </ViewContainer>
                            )
                        }
                        switch (card) {
                        case 'PLAYS':
                            return (
                                <PermissionCheck requiredPermissions={[['TASKS', 'READ']]} key={card + filterByUserId}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        <TasksConnected
                                            embedded
                                            filterByUserId={filterByUserId === null ? 'COMPANY_WIDE' : filterByUserIdForPlays}
                                            showTodayByDefault
                                        />
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'ACCOUNTS_AT_RISK':
                            return (
                                <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!accountsAtRisk ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Table
                                                className={cn.homeTable}
                                                borderless
                                                headers={[
                                                    translateCRMLabel('Account'),
                                                    'Risk',
                                                    'Actions'
                                                ]}
                                                customStyles={{
                                                    1: {
                                                        textAlign: 'center'
                                                    },
                                                    2: {
                                                        width: 140,
                                                    }
                                                }}
                                                data={accountsAtRisk.slice(0, 5).map(row => {
                                                    const accountId = row.find(el => el.type === 'Account')?.externalId
                                                    return [
                                                        <Link to={`/account/${accountId}`}>{row.find(el => el.type === 'Account')?.data}</Link>,
                                                        <HealthScoreConnected data={row.find(el => el.type === 'HealthScore')?.data} accountId={accountId} />,
                                                        <AccountActions
                                                            noTooltip
                                                            account={{ id: accountId, name: row[0]?.data }}
                                                            onDelete={() => getTopRiskPlans(filterByUserId)}
                                                            onReassign={() => getTopRiskPlans(filterByUserId)}
                                                            onShowUsage={() => setShowUsage(accountId)}
                                                        />
                                                    ]
                                                })}
                                            />
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case '10_ACCOUNTS_AT_RISK':
                            return (
                                <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!accountsAtRisk ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Table
                                                className={cn.homeTable}
                                                borderless
                                                headers={[
                                                    translateCRMLabel('Account'),
                                                    'Risk',
                                                    'Actions'
                                                ]}
                                                customStyles={{
                                                    1: {
                                                        textAlign: 'center'
                                                    },
                                                    2: {
                                                        width: 140,
                                                    }
                                                }}
                                                data={accountsAtRisk.map(row => {
                                                    const accountId = row.find(el => el.type === 'Account')?.externalId
                                                    return [
                                                        <Link to={`/account/${accountId}`}>{row.find(el => el.type === 'Account')?.data}</Link>,
                                                        <HealthScoreConnected data={row.find(el => el.type === 'HealthScore')?.data} accountId={accountId} />,
                                                        <AccountActions
                                                            noTooltip
                                                            account={{ id: accountId, name: row[0]?.data }}
                                                            onDelete={() => getTopRiskPlans(filterByUserId)}
                                                            onReassign={() => getTopRiskPlans(filterByUserId)}
                                                            onShowUsage={() => setShowUsage(accountId)}
                                                        />
                                                    ]
                                                })}
                                            />
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'RENEWALS_AT_RISK':
                            return (
                                <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!renewalsAtRisk ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Table
                                                className={cn.homeTable}
                                                borderless
                                                headers={[
                                                    translateCRMLabel('Opportunity'),
                                                    translateCRMLabel('Account'),
                                                    'Amount',
                                                    'Stage',
                                                    'Type',
                                                    'Actions',
                                                ]}
                                                customStyles={{
                                                    5: {
                                                        width: 85
                                                    }
                                                }}
                                                data={renewalsAtRisk.map(el => [
                                                    <Link to={`/opportunities-management/edit/${el.Id}`}>{el.Name}</Link>,
                                                    <Link to={`/account/${el.AccountId}`}>{el.AccountName}</Link>,
                                                    formatLargeSums('$', el.Amount),
                                                    el.Stage,
                                                    el.Type,
                                                    <OpportunityActions
                                                        opportunity={{
                                                            ...el,
                                                            name: el.Name,
                                                            id: el.Id
                                                        }}
                                                        onEdit={getRenewalsAtRisk}
                                                        onRemove={getRenewalsAtRisk}
                                                    />
                                                ])}
                                            />
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'TOP_EXPANSION_OPPORTUNITIES':
                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{getCardLabel(card)}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {!topExpansionOpportunities ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                        <Table
                                            className={cn.homeTable}
                                            borderless
                                            headers={[
                                                translateCRMLabel('Opportunity'),
                                                'Account',
                                                'Amount',
                                                'Stage',
                                                'Actions'
                                            ]}
                                            customStyles={{
                                                4: {
                                                    width: 90,
                                                }
                                            }}
                                            data={topExpansionOpportunities.map(data => {
                                                return [
                                                    data.Name,
                                                    <Link to={`/account/${data.Account?.Id}`}>{data.Account?.Name}</Link>,
                                                    formatLargeSums('$', data.Amount),
                                                    data.StageName,
                                                    <OpportunityActions
                                                        opportunity={{
                                                            ...data,
                                                            name: data.Name,
                                                            id: data.Id
                                                        }}
                                                        onEdit={getTopExpansionOpportunities}
                                                        onRemove={getTopExpansionOpportunities}
                                                    />
                                                ]
                                            })}
                                        />
                                    )}
                                </ViewContainer>
                            )
                        case 'TOP_RENEWAL_OPPORTUNITIES':
                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{getCardLabel(card)}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {!topRenewalsOpportunities ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                        <Table
                                            className={cn.homeTable}
                                            borderless
                                            headers={[
                                                translateCRMLabel('Opportunity'),
                                                'Account',
                                                'Amount',
                                                'Stage',
                                                'Actions'
                                            ]}
                                            customStyles={{
                                                4: {
                                                    width: 90,
                                                }
                                            }}
                                            data={topRenewalsOpportunities.slice(0, 5).map(data => {
                                                return [
                                                    data.Name,
                                                    <Link to={`/account/${data.Account?.Id}`}>{data.Account?.Name}</Link>,
                                                    formatLargeSums('$', data.Amount),
                                                    data.StageName,
                                                    <OpportunityActions
                                                        opportunity={{
                                                            ...data,
                                                            name: data.Name,
                                                            id: data.Id
                                                        }}
                                                        onEdit={getTopRenewalsOpportunities}
                                                        onRemove={getTopRenewalsOpportunities}
                                                    />
                                                ]
                                            })}
                                        />
                                    )}
                                </ViewContainer>
                            )
                        case 'TOP_RENEWAL_10_OPPORTUNITIES':
                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{getCardLabel(card)}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {!topRenewalsOpportunities ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                        <Table
                                            className={cn.homeTable}
                                            borderless
                                            headers={[
                                                translateCRMLabel('Opportunity'),
                                                'Account',
                                                'Amount',
                                                'Stage',
                                                'Actions'
                                            ]}
                                            customStyles={{
                                                4: {
                                                    width: 90,
                                                }
                                            }}
                                            data={topRenewalsOpportunities.map(data => {
                                                return [
                                                    data.Name,
                                                    <Link to={`/account/${data.Account?.Id}`}>{data.Account?.Name}</Link>,
                                                    formatLargeSums('$', data.Amount),
                                                    data.StageName,
                                                    <OpportunityActions
                                                        opportunity={{
                                                            ...data,
                                                            name: data.Name,
                                                            id: data.Id
                                                        }}
                                                        onEdit={getTopRenewalsOpportunities}
                                                        onRemove={getTopRenewalsOpportunities}
                                                    />
                                                ]
                                            })}
                                        />
                                    )}
                                </ViewContainer>
                            )
                        case 'EXPANSIONS':
                            return (
                                <PermissionCheck requiredPermissions={[['GLOBAL_WHITE_SPACE_PLANNING', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!accountsAtExpansion ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Table
                                                className={cn.homeTable}
                                                borderless
                                                headers={[
                                                    translateCRMLabel('Account'),
                                                    ['Current', 'Spend'],
                                                    ['Up Sell', 'Potential'],
                                                    ['Cross Sell', 'Potential'],
                                                    'Actions'
                                                ]}
                                                customStyles={{
                                                    4: {
                                                        width: 140,
                                                    }
                                                }}
                                                data={
                                                    accountsAtExpansion.map(row => {
                                                        const accountId = row[0]?.externalId
                                                        return [
                                                            <Link to={`/account/${accountId}`} data-tooltip-id='default' data-tooltip-content={row[0].dataTip}>{row[0]?.data}</Link>,
                                                            <div>{formatLargeSums('$', row[1])}</div>,
                                                            <div>{formatLargeSums('$', row[2])}</div>,
                                                            <div>{formatLargeSums('$', row[3])}</div>,
                                                            <AccountActions
                                                                noTooltip
                                                                account={{ id: accountId, name: row[0]?.data?.split(' (').slice(0, row[0].data.split(' (').length - 1).join(' (') }}
                                                                onDelete={() => getTopRiskPlans(filterByUserId)}
                                                                onReassign={() => getTopRiskPlans(filterByUserId)}
                                                                onShowUsage={() => setShowUsage(accountId)}
                                                            />
                                                        ]
                                                    })
                                                }
                                            />
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'MEETINGS_TODAY':
                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{getCardLabel(card)}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {!meetingsToday ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                        <Table
                                            className={cn.homeTable}
                                            borderless
                                            headers={[
                                                'Time',
                                                translateCRMLabel('Account'),
                                                'Contacts'
                                            ]}
                                            customStyles={{
                                                0: {
                                                    width: 95
                                                },
                                                1: {
                                                    width: 200
                                                }
                                            }}
                                            data={
                                                meetingsToday.events.map(meeting => {
                                                    return [
                                                        moment(meeting.start.dateTime || meeting.start.date).tz(meeting.start.timeZone || meeting.start.date).format('hh:mm A'),
                                                        <Row className={cn.accountLinksWrapper} noMargin wrap>
                                                            {!!meeting.accountsData.length ? meeting.accountsData.map((account, i) => (
                                                                <>
                                                                    <Link className={cn.accountLink} to={`/account/${account.id}`}>{account.name}</Link>
                                                                    {i < meeting.accountsData.length - 1 ? <>,<Nbsp /></> : ''}
                                                                </>
                                                            )) : (
                                                                <div className={cn.accountLink}>
                                                                    {meeting.title}
                                                                </div>
                                                            )}
                                                        </Row>,
                                                        <Row noMargin wrap>
                                                            {meeting.attendees.map((attendee, i) => {
                                                                const sfdcContact = meetingsToday.contacts.find(el => el.Email === attendee.email)
                                                                if (sfdcContact) {
                                                                    const name = sfdcContact.FirstName && sfdcContact.LastName ? `${sfdcContact.FirstName} ${sfdcContact.LastName}` : sfdcContact.FirstName || sfdcContact.LastName || sfdcContact.Email
                                                                    return <>
                                                                        <Link
                                                                            data-tooltip-id='default'
                                                                            data-tooltip-content={attendee.responseStatus}
                                                                            to={`account/${sfdcContact.AccountId}/contact/${sfdcContact.Id}`}
                                                                        >
                                                                            {name}
                                                                        </Link>
                                                                        {i < meeting.attendees.length - 1 ? <>,<Nbsp /></> : ''}
                                                                    </>
                                                                }
                                                                return <div
                                                                    className={cn.attendeeEmail}
                                                                    data-tooltip-id='default'
                                                                    data-tooltip-content={attendee.responseStatus}
                                                                >
                                                                    {`${attendee.displayName || attendee.email}`}
                                                                    {i < meeting.attendees.length - 1 ? <>,<Nbsp /></> : ''}
                                                                </div>
                                                            })}
                                                        </Row>
                                                    ]
                                                })
                                            }
                                        />
                                    )}
                                </ViewContainer>
                            )
                        case 'ACCOUNTS_GRAPH':
                            return (
                                <PermissionCheck requiredPermissions={[['METRICS', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!metricsThisMonth ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Row noMargin spaceBetween className={cn.center}>
                                                <div className={`${cn.doughnutWrapper}`}>
                                                    <Doughnut
                                                        data={{
                                                            ...accountsBreakdownData,
                                                            datasets: [{
                                                                ...accountsBreakdownData.datasets[0],
                                                                data: [
                                                                    !Object.keys(metricsThisMonth?.accountStats?.churnedAccounts || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.accountsWithContractedOpps || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.renewedAccounts || {}).length
                                                                        ? 1 : Object.keys(metricsThisMonth?.accountStats?.churnedAccounts).length,
                                                                    !Object.keys(metricsThisMonth?.accountStats?.churnedAccounts || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.accountsWithContractedOpps || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.renewedAccounts || {}).length
                                                                        ? 1 : Object.keys(metricsThisMonth?.accountStats?.accountsWithContractedOpps).length,
                                                                    !Object.keys(metricsThisMonth?.accountStats?.churnedAccounts || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.accountsWithContractedOpps || {}).length
                                                                    && !Object.keys(metricsThisMonth?.accountStats?.renewedAccounts || {}).length
                                                                        ? 1 : Object.keys(metricsThisMonth?.accountStats?.renewedAccounts).length,
                                                                ]
                                                            }]
                                                        }}
                                                        options={doughnutOptions}
                                                    />
                                                </div>
                                                <div className={cn.accountsAtRiskGraphDetails}>
                                                    <AccountsHeading
                                                        path={'/metrics'}
                                                        count={totalAccounts}
                                                    />
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='orange'
                                                            label={`${metricsThisMonth?.accountStats?.churnedAccounts && Object.keys(metricsThisMonth.accountStats.churnedAccounts).length} Churned ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialLoss>
                                                            Total Loss of {formatLargeSums(
                                                                '$',
                                                                (
                                                                    metricsThisMonth?.accountStats?.churnedAccounts || []
                                                                ).filter(el => !!el).map(el => {
                                                                    return metricsThisMonth?.accounts && metricsThisMonth?.accounts[el]?.AnnualRevenue
                                                                }).reduce((a, b) => a + b, 0) || 0
                                                            )}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='blueish'
                                                            label={`${metricsThisMonth?.accountStats?.accountsWithContractedOpps && Object.keys(metricsThisMonth.accountStats.accountsWithContractedOpps).length} Contracted ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialLoss>
                                                            Total Loss of {formatLargeSums(
                                                                '$',
                                                                Object.values(metricsThisMonth?.accountStats?.accountsWithContractedOpps || {})
                                                                    .reduce((a, b) => a + b, 0) || 0
                                                            )}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='greenish'
                                                            label={`${metricsThisMonth?.accountStats?.renewedAccounts && Object.keys(metricsThisMonth.accountStats.renewedAccounts).length} Renewed ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialGain>
                                                            Total Gain of {formatLargeSums(
                                                                '$',
                                                                Object.values(metricsThisMonth?.opportunities || {})
                                                                    .filter(el => el.Type === settings.renewalType && metricsThisMonth?.accountStats?.renewedAccounts?.includes(el.AccountId) && new Date(el.CloseDate).getMonth() === new Date().getMonth() && new Date(el.CloseDate).getFullYear() === new Date().getFullYear())
                                                                    .map(el => el.Amount)
                                                                    .reduce((a, b) => a + b, 0) || 0
                                                            )}
                                                        </PotentialGain>
                                                    </GraphExplanationWrapper>
                                                </div>
                                            </Row>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'EXPANSION_GRAPH':
                            return (
                                <PermissionCheck requiredPermissions={[['GLOBAL_WHITE_SPACE_PLANNING', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {expansionBreakDown === undefined ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Row noMargin spaceBetween>
                                                <div className={`${cn.doughnutWrapper}`}>
                                                    <Doughnut
                                                        data={{
                                                            ...doughnutData,
                                                            datasets: [
                                                                {
                                                                    ...doughnutData.datasets[0],
                                                                    data: [expansionBreakDownUpSellSum || 1, expansionBreakDownCrossSellSum || 1],
                                                                    backgroundColor: [
                                                                        '#ABBDD3',
                                                                        '#298072'
                                                                    ]
                                                                },
                                                            ],
                                                        }}
                                                        options={doughnutOptions}
                                                    />
                                                </div>
                                                <div className={cn.accountsAtRiskGraphDetails}>
                                                    <AccountsHeading
                                                        path={'/global-white-space-planning/0'}
                                                        count={totalAccounts}
                                                    />
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='greenish'
                                                            label={`Potential for Up Sell`}
                                                        />
                                                        <PotentialGain>
                                                            Potential Gain {formatLargeSums('$', expansionBreakDownUpSellSum)}
                                                        </PotentialGain>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='blueish'
                                                            label={`Potential for Cross Sell`}
                                                        />
                                                        <PotentialGain>
                                                            Potential Gain {formatLargeSums('$', expansionBreakDownCrossSellSum)}
                                                        </PotentialGain>
                                                    </GraphExplanationWrapper>
                                                </div>
                                            </Row>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'ACCOUNTS_HEALTH_BREAKDOWN_VS_LAST_MONTH':
                            return (
                                <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!riskLastMonth ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Row noMargin spaceBetween className={cn.center}>
                                                <div className={`${cn.doughnutWrapper}`}>
                                                    <Doughnut
                                                        data={{
                                                            ...doughnutData,
                                                            datasets: [
                                                                {
                                                                    ...doughnutData.datasets[0],
                                                                    backgroundColor: [
                                                                        '#CB3D2C',
                                                                        '#FFC909',
                                                                        '#27C400',
                                                                    ],
                                                                    data: [
                                                                        !accountsBecameRed?.count
                                                                        && !accountsBecameYellow?.count
                                                                        && !accountsBecameGreen?.count
                                                                            ? 1 : accountsBecameRed.count,

                                                                        !accountsBecameRed?.count
                                                                        && !accountsBecameYellow?.count
                                                                        && !accountsBecameGreen?.count
                                                                            ? 1 : accountsBecameYellow.count,

                                                                        !accountsBecameRed?.count
                                                                        && !accountsBecameYellow?.count
                                                                        && !accountsBecameGreen?.count
                                                                            ? 1 : accountsBecameGreen.count,
                                                                    ],
                                                                },
                                                            ],
                                                        }}
                                                        options={doughnutOptions}
                                                    />
                                                </div>
                                                <div className={cn.accountsAtRiskGraphDetails}>
                                                    <AccountsHeading
                                                        path={'/risk/0'}
                                                        count={totalAccounts}
                                                    />
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='red'
                                                            label={`${accountsBecameRed.count} ${translateCRMLabel('Accounts')} Became Red`}
                                                        />
                                                        <PotentialLoss>
                                                            Potential Loss {formatLargeSums('$', accountsBecameRed.arr)}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='yellow'
                                                            label={`${accountsBecameYellow.count} ${translateCRMLabel('Accounts')} Became Yellow`}
                                                        />
                                                        <PotentialLoss>
                                                            Potential Loss {formatLargeSums('$', accountsBecameYellow.arr)}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='green'
                                                            label={`${accountsBecameGreen.count} ${translateCRMLabel('Accounts')} Became Green`}
                                                        />
                                                        <PotentialGain>
                                                            Potential Gain {formatLargeSums('$', accountsBecameGreen.arr)}
                                                        </PotentialGain>
                                                    </GraphExplanationWrapper>
                                                </div>
                                            </Row>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'ACCOUNTS_HEALTH_BREAKDOWN':
                            return (
                                <PermissionCheck requiredPermissions={[['RISK_MANAGEMENT', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!accountsRiskBreakdown ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <Row noMargin spaceBetween className={cn.center}>
                                                <div className={`${cn.doughnutWrapper}`}>
                                                    <Doughnut
                                                        data={{
                                                            ...doughnutData,
                                                            datasets: [
                                                                {
                                                                    ...doughnutData.datasets[0],
                                                                    backgroundColor: [
                                                                        '#CB3D2C',
                                                                        '#FFC909',
                                                                        '#27C400',
                                                                    ],
                                                                    data: [
                                                                        !accountsRiskBreakdown?.redAccounts?.count
                                                                        && !accountsRiskBreakdown?.yellowAccounts?.count
                                                                        && !accountsRiskBreakdown?.greenAccounts?.count
                                                                            ? 1 : accountsRiskBreakdown?.redAccounts?.count,

                                                                        !accountsRiskBreakdown?.redAccounts?.count
                                                                        && !accountsRiskBreakdown?.yellowAccounts?.count
                                                                        && !accountsRiskBreakdown?.greenAccounts?.count
                                                                            ? 1 : accountsRiskBreakdown?.yellowAccounts?.count,

                                                                        !accountsRiskBreakdown?.redAccounts?.count
                                                                        && !accountsRiskBreakdown?.yellowAccounts?.count
                                                                        && !accountsRiskBreakdown?.greenAccounts?.count
                                                                            ? 1 : accountsRiskBreakdown?.greenAccounts?.count,
                                                                    ],
                                                                },
                                                            ],
                                                        }}
                                                        options={doughnutOptions}
                                                    />
                                                </div>
                                                <div className={cn.accountsAtRiskGraphDetails}>
                                                    <AccountsHeading
                                                        path={'/risk/0'}
                                                        count={totalAccounts}
                                                    />
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='red'
                                                            label={`${accountsRiskBreakdown?.redAccounts?.count} Red ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialLoss>
                                                            Potential Loss of {formatLargeSums('$', accountsRiskBreakdown?.redAccounts?.revenue)}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='yellow'
                                                            label={`${accountsRiskBreakdown?.yellowAccounts?.count} Yellow ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialLoss>
                                                            Potential Loss of {formatLargeSums('$', accountsRiskBreakdown?.yellowAccounts?.revenue)}
                                                        </PotentialLoss>
                                                    </GraphExplanationWrapper>
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            type='green'
                                                            label={`${accountsRiskBreakdown?.greenAccounts?.count} Green ${translateCRMLabel('Accounts')}`}
                                                        />
                                                        <PotentialGain>
                                                            Total ARR {formatLargeSums('$', accountsRiskBreakdown?.greenAccounts?.revenue)}
                                                        </PotentialGain>
                                                    </GraphExplanationWrapper>
                                                </div>
                                            </Row>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'BOOK_OF_BUSINESS':
                            return (
                                <ViewContainer className={`${cn.cardWrapper} ${cn.smaller}`} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{getCardLabel(card)}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    {bookOfBusiness === undefined ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                        <div className={cn.bookOfBusiness}>
                                            <span>{formatLargeSums('$', bookOfBusiness)}</span>
                                        </div>
                                    )}
                                </ViewContainer>
                            )
                        case 'EXPANSION_OVER_TIME':
                            return (
                                <PermissionCheck requiredPermissions={[['GLOBAL_WHITE_SPACE_PLANNING', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!expansionHistoricalData ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <div className={cn.barGraphWrapper}>
                                                <Bar
                                                    data={{
                                                        ...expansionData,
                                                        datasets: [
                                                            {
                                                                ...expansionData.datasets[0],
                                                                data: [
                                                                    expansionHistoricalData?.lastQuarter?.summary || 0,
                                                                    expansionHistoricalData?.lastMonth?.summary || 0,
                                                                    expansionHistoricalData?.thisMonth?.summary || 0,
                                                                ]
                                                            }
                                                        ]
                                                    }}
                                                    options={options}
                                                />
                                            </div>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'CONTRACTION':
                            return (
                                <PermissionCheck requiredPermissions={[['METRICS', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!metricsPerMonth ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <div className={cn.barGraphWrapper}>
                                                <Bar
                                                    data={{
                                                        ...churnData,
                                                        datasets: [{
                                                            ...churnData.datasets[0],
                                                            data: metricsPerMonth.reverse().map(el => (
                                                                Object.values(el.accountStats?.accountsWithContractedOpps || {}).reduce((a, b) => a + b, 0)
                                                            ))
                                                        }]
                                                    }}
                                                    options={{
                                                        ...options,
                                                        plugins: [circleArrow]
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        case 'CHURN':
                            return (
                                <PermissionCheck requiredPermissions={[['METRICS', 'READ']]} key={card}>
                                    <ViewContainer className={cn.cardWrapper} testId={card}>
                                        <Row noMargin spaceBetween fullWidth>
                                            <Heading>{getCardLabel(card)}</Heading>
                                            <RemoveOrRearrangeDropdown
                                                positions={cards.length}
                                                value={cardIndex}
                                                onChange={e => updateOnChange(e.target.value, card)}
                                            />
                                        </Row>
                                        <br />
                                        {!metricsPerMonth ? <Loader size={LOADER_SIZES.BIG} type={LOADER_TYPES.SPINNER} /> : (
                                            <div className={cn.barGraphWrapper}>
                                                <Bar
                                                    data={{
                                                        ...churnData,
                                                        datasets: [{
                                                            ...churnData.datasets[0],
                                                            data: metricsPerMonth.reverse().map(el => (
                                                                (el.accountStats?.churnedAccounts || [])
                                                                    .filter(el => !!el).map(el => {
                                                                        return metricsThisMonth?.accounts && metricsThisMonth?.accounts[el]?.AnnualRevenue
                                                                    }).reduce((a, b) => a + b, 0)
                                                            ))
                                                        }]
                                                    }}
                                                    options={{
                                                        ...options,
                                                        plugins: [circleArrow]
                                                    }}
                                                />
                                            </div>
                                        )}
                                    </ViewContainer>
                                </PermissionCheck>
                            )
                        default:
                            const data = customGraphData[card]
                            if (!data) {
                                return null
                            }
                            const graphData = [
                                ...Object.values(data.remappedData),
                            ]

                            if (data.totalBookOfBusinessValue) {
                                graphData.push(data.totalBookOfBusinessValue)
                            }

                            if (data.totalNumberOfAccounts) {
                                graphData.push(data.totalNumberOfAccounts)
                            }

                            return (
                                <ViewContainer className={cn.cardWrapper} testId={card} key={card}>
                                    <Row noMargin spaceBetween fullWidth>
                                        <Heading>{data.customGraph.label}</Heading>
                                        <RemoveOrRearrangeDropdown
                                            positions={cards.length}
                                            value={cardIndex}
                                            onChange={e => updateOnChange(e.target.value, card)}
                                        />
                                    </Row>
                                    <br />
                                    <Row noMargin spaceBetween className={cn.center}>
                                        <div className={`${cn.doughnutWrapper}`}>
                                            <Doughnut
                                                data={{
                                                    ...doughnutData,
                                                    datasets: [
                                                        {
                                                            ...doughnutData.datasets[0],
                                                            data: graphData,
                                                            backgroundColor: [
                                                                ...data.customGraphProperties.map(el => el.color),
                                                                '#3EB984',
                                                                '#3EB984',
                                                            ].filter(el => !!el)
                                                        },
                                                    ],
                                                }}
                                                options={doughnutOptions}
                                            />
                                        </div>
                                        <div className={cn.accountsAtRiskGraphDetails}>
                                            {data.customGraphProperties.find(el => !!el.divideByProperty)
                                                ? Object.keys(data.remappedData).map(key => (
                                                    <GraphExplanationWrapper>
                                                        <ExplanationLabel
                                                            label={`${key} ${formatLargeSums('$', data.remappedData[key])}`}
                                                            type={'green'}
                                                        />
                                                    </GraphExplanationWrapper>
                                                )) : (
                                                    data.customGraphProperties.map(el => (
                                                        <>
                                                            <Row noMargin>
                                                                <span>
                                                                    {el.label} - {el.format === 'SUM_CASH' ? formatLargeSums('$', data.remappedData[el.property]) : data.remappedData[el.property]}
                                                                </span>
                                                            </Row>
                                                            {!!el.divideByCash && (
                                                                <Row noMargin>
                                                                    <span>
                                                                        Total Book of Business - {formatLargeSums('$', data.totalBookOfBusinessValue)}
                                                                    </span>
                                                                </Row>
                                                            )}
                                                            {!!el.divideByCount && (
                                                                <Row noMargin>
                                                                    <span>
                                                                        Total Book of Business - {data.totalNumberOfAccounts}
                                                                    </span>
                                                                </Row>
                                                            )}
                                                        </>
                                                    ))
                                                )}
                                        </div>
                                    </Row>
                                </ViewContainer>
                            )
                        }
                    })
                }
            </div>
            <UsageGraph
                shown={!!showUsage}
                onClose={() => setShowUsage(false)}
                accountId={showUsage}
            />
        </div>
    )
}


export const mapStateToProps = (state, ownProps) => {
    return {
        ...state,
        ...ownProps,
    }
}

export const Home2Connected = connect(mapStateToProps)(Home2)