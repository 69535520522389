import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import { faBuilding, faChartLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux'
import { ViewContainer } from '../../../components/ViewContainer'
import cn from './Account.module.css'
import { Button, BUTTON_TYPES } from '../../../components/Button'
import { makeRequest } from '../../../utilities/endpoints'
import { URLS } from '../../../config'
import { Modal } from '../../../components/Modal'
import { Row } from '../../../components/Row'
import { Tabs } from '../../../components/Tabs'
import { permissionCheck } from '../../../utilities/permissionCheck';
import { workspaceTypeCheck } from '../../../utilities/workspaceType';
import { isZendeskConnected } from '../../../utilities/isZendeskConnected';
import { Nbsp } from '../../../components/Nbsp';
import { environmentCheck } from '../../../utilities/environmentCheck';
import { changeCustomRouteLabel } from '../../../store/actions';
import { HealthScoreConnected } from '../../../components/HealthScore';
import { Input } from '../../../components/Input';
import { LOADER_TYPES, Loader } from '../../../components/Loader';
import { LinkedIn } from '../../../assets/icons/LinkedIn';
import { UsageGraph } from './components/UsageGraph';
import { Flag } from '../../../assets/icons/Flag';
import { ContactNotes } from '../../../assets/icons/ContactNotes';
import { Ticket } from '../../../assets/icons/Ticket';
import { Expansions } from '../../../assets/icons/Expansions';
import { People } from '../../../assets/icons/People';
import { TAB_OPTIONS, TopPart } from './components/TopPart';
import { BOTTOM_RIGHT_PART_OPTIONS, BottomRightPart } from './components/BottomRightPart';
import { isFreshdeskConnected } from '../../../utilities/isFreshdeskConnected';

export const Account = ({ dispatch }) => {
    const [accountDetails, setAccountDetails] = useState(null)
    const [contacts, setContacts] = useState([])
    const [selectedAction, setSelectedAction] = useState(environmentCheck(['LOCAL', 'DEV']) ? BOTTOM_RIGHT_PART_OPTIONS.BUSINESS_GOALS : BOTTOM_RIGHT_PART_OPTIONS.OPPORTUNITIES)
    const [zendeskConnected, setZendeskConnected] = useState(false)
    const [freshdeskConnected, setFreshdeskConnected] = useState(false)
    const [numberOfPeopleWorking, setNumberOfPeopleWorking] = useState([])
    const [logoMissing, setLogoMissing] = useState(false)
    const [risk, setRisk] = useState(null)
    const [editLinkedinUrl, setEditLinkedinUrl] = useState(false)
    const [searchingForNewUrl, setSearchingForNewUrl] = useState(false)
    const [manualLinkedInUrl, setManualLinkedInUrl] = useState()
    const [showGraph, setShowGraph] = useState(false)
    const [selectedTopTab, setSelectedTopTab] = useState(0)

    const getRisk = id => {
        makeRequest.get(`${URLS.main}/account/${id}/risk`)
            .then(res => setRisk(res.data))
    }

    const { id, noteId } = useParams();

    const loadContacts = () => {
        if (permissionCheck('ACCOUNT_CONTACTS', 'READ')) {
            makeRequest.get(`${URLS.main}/contacts/${id}`)
                .then(res => {
                    if (res && res.data) {
                        setContacts(res.data)
                    }
                })
        }
    }

    useEffect(() => {
        makeRequest.get(`${URLS.main}/account/${id}`)
            .then(res => {
                if (res && res.data) {
                    if (workspaceTypeCheck(['HUBSPOT'])) {
                        setNumberOfPeopleWorking([{ numberOfPeople: res.data.numberOfPeople }])
                    }
                    setAccountDetails(res.data)
                    dispatch(changeCustomRouteLabel(res.data?.name))
                }
            })
        // loadOpportunities()
        loadContacts()

        if (!workspaceTypeCheck(['HUBSPOT'])) {
            loadNumberOfPeopleWorking()
        }
        isZendeskConnected().then(data => setZendeskConnected(data.isConnected))
        isFreshdeskConnected().then(data => setFreshdeskConnected(data.isConnected))
        getRisk(id)
    }, [id])

    const loadNumberOfPeopleWorking = () => {
        makeRequest.get(`${URLS.main}/account/${id}/peopleWorkingThere`)
            .then(res => {
                if (res && res.data) {
                    setNumberOfPeopleWorking(res.data.reverse())
                }
            })
    }

    const updateManualLinkedInUrl = () => {
        makeRequest.post(`${URLS.main}/account/setManualLinkedInPage`, {
            accountId: accountDetails?.id,
            url: manualLinkedInUrl
        })
            .then((res) => {
                if (res && res.data) {
                    setAccountDetails({
                        ...accountDetails,
                        linkedinCompanyUrl: manualLinkedInUrl,
                        linkedinEmployeesUrl: res.data.url
                    })
                }
                setSearchingForNewUrl(false)
                setManualLinkedInUrl(null)
            })
        setEditLinkedinUrl(false)
        setSearchingForNewUrl(true)
    }

    return (
        <div className={cn.account}>
            <ViewContainer>
                <Row spaceBetween noMargin>
                    <Row noMargin>
                        <div className={cn.accountLogo} data-tooltip-id='default' data-tooltip-content='Logo provided by Clearbit'>
                            {accountDetails?.website && !logoMissing ? (
                                <img
                                    src={`https://logo.clearbit.com/${accountDetails.website}`}
                                    onError={() => setLogoMissing(true)}
                                    alt='Account Logo'
                                />
                            ) : (
                                <FontAwesomeIcon icon={faBuilding} />
                            )}
                        </div>
                        <div className={cn.headingContent}>
                            <span>
                                {accountDetails?.name}
                            </span>
                            <div className={cn.healthscoreWrapper}>
                                <HealthScoreConnected data={risk || {}} accountId={id} />
                            </div>
                        </div>
                    </Row>
                    <Row noMargin>
                        {searchingForNewUrl ? (
                            <Loader type={LOADER_TYPES.SPINNER} />
                        ) : (
                            <Button
                                type={BUTTON_TYPES.PADDED_ICON}
                                customIcon={<LinkedIn fill={accountDetails?.linkedinCompanyUrl ? '#0a66c2' : '#A0A4AA'} />}
                                onClick={() => {
                                    setEditLinkedinUrl(true)
                                    setManualLinkedInUrl(accountDetails?.linkedinCompanyUrl)
                                }}
                                data-tooltip-id='default'
                                data-tooltip-content={
                                    accountDetails?.linkedinCompanyUrl ? "Click to edit."
                                        : "We have not been able to find the linkedin URL for this company. Click to edit it."
                                }
                            />
                        )}
                        <Nbsp />
                        <Button
                            disabled={false}
                            icon={faChartLine}
                            type={BUTTON_TYPES.ICON}
                            onClick={() => {
                                setShowGraph(true)
                            }}
                        />
                    </Row>
                </Row>
                <Tabs
                    useIds
                    onChange={(index) => setSelectedTopTab(index)}
                    selected={selectedTopTab}
                    options={[
                        { icon: <Expansions />, label: 'Account Details', id: TAB_OPTIONS.DETAILS },
                        environmentCheck(['DEV', 'LOCAL']) ? { icon: <Flag />, label: 'Mission Statement', id: TAB_OPTIONS.MISSION_STATEMENT } : null,
                        { icon: <People />, label: 'Account Notes', id: TAB_OPTIONS.ACCOUNT_NOTES },
                        { icon: <ContactNotes />, label: 'Contact Notes', id: TAB_OPTIONS.CONTACT_NOTES },
                        zendeskConnected || freshdeskConnected ? { icon: <Ticket />, label: 'Support Tickets', id: TAB_OPTIONS.TICKETS } : null,
                    ].filter(el => !!el)}
                />
                <TopPart
                    noteId={noteId}
                    selectedTab={selectedTopTab}
                    accountDetails={accountDetails}
                    numberOfPeopleWorking={numberOfPeopleWorking}
                    contacts={contacts}
                />
            </ViewContainer>

            <div className={cn.bottomPart}>
                <ViewContainer className={cn.leftSide}>
                    <div className={cn.actionsLabel}>
                        Actions
                    </div>
                    <div className={cn.actionsList}>
                        {environmentCheck(['LOCAL', 'DEV']) ? (
                            <div
                                className={`${cn.actionListItem} ${selectedAction === BOTTOM_RIGHT_PART_OPTIONS.BUSINESS_GOALS ? cn.active : ''}`}
                                onClick={() => setSelectedAction(BOTTOM_RIGHT_PART_OPTIONS.BUSINESS_GOALS)}
                            >
                                <div className={cn.indicator} />
                                Business Goals
                            </div>
                        ) : null}
                        {environmentCheck(['LOCAL', 'DEV']) ? (
                            <div
                                className={`${cn.actionListItem} ${selectedAction === BOTTOM_RIGHT_PART_OPTIONS.EMAILS ? cn.active : ''}`}
                                onClick={() => setSelectedAction(BOTTOM_RIGHT_PART_OPTIONS.EMAILS)}
                            >
                                <div className={cn.indicator} />
                                Emails
                            </div>
                        ) : null }
                        {environmentCheck(['LOCAL', 'DEV']) ? (
                            <div
                                className={`${cn.actionListItem} ${selectedAction === BOTTOM_RIGHT_PART_OPTIONS.CUSTOMER_JOURNEY ? cn.active : ''}`}
                                onClick={() => setSelectedAction(BOTTOM_RIGHT_PART_OPTIONS.CUSTOMER_JOURNEY)}
                            >
                                <div className={cn.indicator} />
                                Customer Journey
                            </div>
                        ) : null }
                        {permissionCheck('OPPORTUNITIES', 'READ') ? (
                            <div
                                className={`${cn.actionListItem} ${selectedAction === BOTTOM_RIGHT_PART_OPTIONS.OPPORTUNITIES ? cn.active : ''}`}
                                onClick={() => setSelectedAction(BOTTOM_RIGHT_PART_OPTIONS.OPPORTUNITIES)}
                            >
                                <div className={cn.indicator} />
                                Opportunities
                            </div>
                        ) : null}
                        <div
                            className={`${cn.actionListItem} ${selectedAction === BOTTOM_RIGHT_PART_OPTIONS.CONTACTS ? cn.active : ''}`}
                            onClick={() => setSelectedAction(BOTTOM_RIGHT_PART_OPTIONS.CONTACTS)}
                        >
                            <div className={cn.indicator} />
                            Contacts
                        </div>
                    </div>
                </ViewContainer>
                <BottomRightPart
                    onContactAdded={loadContacts}
                    type={selectedAction}
                    accountId={id}
                    contacts={contacts}
                />
            </div>

            {editLinkedinUrl && (
                <Modal
                    header={'Edit Company\'s LinkedIn URL'}
                    onClose={() => setEditLinkedinUrl(false)}
                    buttons={<Row spaceBetween fullWidth>
                        <Button
                            onClick={updateManualLinkedInUrl}
                        >
                            Update
                        </Button>
                        <Button
                            onClick={() => setEditLinkedinUrl(false)}
                        >
                            Cancel
                        </Button>
                    </Row>}
                >
                    <Input
                        value={manualLinkedInUrl}
                        placeholder='format as https://www.linkedin.com/company/Callypso/'
                        onChange={e => setManualLinkedInUrl(e.target.value)}
                        fullWidth
                    />
                </Modal>
            )}
            <UsageGraph
                accountId={id}
                shown={showGraph}
                onClose={() => setShowGraph(false)}
            />
        </div>
    )
}

export const mapStateToProps = (state) => {
    return {
        user: state.user,
    }
}

export const AccountConnected = connect(mapStateToProps)(Account)
